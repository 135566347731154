export default {
    usersLinked: {
        title: 'Alunos vinculados',
        count: 0,
        icon: 'mdi-account-tie',
        path: 'users_company'
    },
    managersLinked: {
        title: 'Profissionais vinculados',
        count: 0,
        icon: 'mdi-table-account',
        path: 'managers_company'
    },
    groups: {
        sector: 'groups',
        title: 'Grupos',
        count: 0,
        icon: 'mdi-account-supervisor-circle',
        path: 'groups_company'
    },
    campaigns: {
        title: 'Campanhas',
        count: 0,
        icon: 'mdi-vote-outline',
        path: 'campaigns_company'
    }
};
