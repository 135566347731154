<template>
    <v-container class="sector">
        <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar color="primary" dark> Bullying </v-toolbar>
                <v-card-text class="info-bullying">
                    <h2>{{ bullying.title }}</h2>
                    <p>{{ bullying.text }}</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout justify-center align-center>
            <v-flex xs12>
                <v-container class="dashs" v-show="showDashs">
                    <v-row>
                        <v-col
                            v-for="(dash, index) of dashs"
                            :key="dash.sector"
                            class="dash-item"
                            :class="firstItemComputed(index)">
                            <div class="icon">
                                <v-icon x-large color="black">{{ dash.icon }}</v-icon>
                            </div>
                            <div class="count">{{ dash.count }}</div>
                            <div class="title">{{ dash.title }}</div>
                            <div class="box-button">
                                <v-btn class="btn-gear" @click="goTo(dash.path)">
                                    Gerenciar
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import dashConfig from '../configs/dash.company.config';
    import messages from '@/mocks/info.mock';

    export default {
        name: 'DashCompany',
        data() {
            return {
                showDashs: false,
                dialog: false,
                bullying: {
                    id: null,
                    role: null,
                    title: null,
                    text: null
                },
                dashs: []
            };
        },
        mounted() {
            this.setMessageBullying();

            this.populateCompanyStatistics();
        },
        computed: {
            ...mapGetters({
                user: 'getDataUser'
            }),
            firstItemComputed() {
                return (index) => {
                    return {
                        'dash-item-first': !index ? true : false
                    };
                };
            }
        },
        methods: {
            setMessageBullying() {
                const messagesFiltereds = messages.messages.filter(
                    (e) => e.role === 'ALL' || e.role === 'COMPANY'
                );

                const randomize = (min, max) => {
                    return Math.floor(Math.random() * (max - min)) + min;
                };

                const totalMessages = messagesFiltereds.length;

                const index = randomize(0, totalMessages);

                this.bullying = messagesFiltereds[index];

                this.dialog = true;
            },
            goTo(path) {
                if (this.user) {
                    return this.$router.push({ path });
                }

                return this.$router.push({ name: 'signin' });
            },
            dataAdapter(data) {
                const aux = [];

                let key = null,
                    value = null,
                    field = null;

                data.forEach((e) => {
                    key = e.key;
                    value = e.value;

                    field = dashConfig[key];
                    field.count = value;

                    aux.push(field);
                });

                return aux;
            },
            dataFilter(data) {
                const aux = [];

                const fields = ['usersLinked', 'managersLinked', 'groups', 'campaigns'];

                Object.keys(data).forEach((key) => {
                    if (fields.includes(key)) {
                        aux.push({
                            key,
                            value: data[key]
                        });
                    }
                });

                return aux;
            },
            populateCompanyStatistics() {
                this.$store.commit('setGlobalLoading', true);

                this.$service.http['company']
                    .getCompanyStatistics()
                    .then((result) => {
                        const record = result.data.doc;

                        const dataFiltered = this.dataFilter(record);

                        const dataAdpted = this.dataAdapter(dataFiltered);

                        this.dashs = dataAdpted;
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.showDashs = true;
                        this.$store.commit('setGlobalLoading', false);
                    });
            }
        }
    };
</script>

<style scoped>
    .info-bullying h2 {
        margin-top: 20px;
        font-size: 28px;
        line-height: 1.3em;
        color: #000;
    }

    .info-bullying p {
        margin-top: 20px;
        font-size: 18px;
        color: #000;
        font-family: arial;
    }

    .sector {
        width: 100%;
    }

    .sector-flex {
        width: 100%;
    }

    .sector .sector-head-title {
        margin-bottom: 30px;
        text-align: center;
    }

    .dashs .dash-item {
        position: relative;
        margin: 20px;
        height: 180px;
        background-color: #fff;
    }

    .dashs .dash-item .count {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 50px;
    }
    /*
    .dashs .dash-item .icon {
    } */

    .dashs .dash-item .title {
        position: absolute;
        font-size: 16px !important;
        font-weight: normal !important;
        color: #000;
        right: 20px;
        top: 70px;
    }

    .dashs .dash-item .box-button {
        position: relative;
        top: 55px;
        border-top: 1px solid #e0e0e0;
    }

    .btn-gear {
        margin-top: 10px;
        text-transform: none !important;
    }
</style>
